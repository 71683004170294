import { EnumEnvs } from '@library/store/config/config.interface';

declare global {
  interface Window {
      env: {
        envDomain: string
      }
  }
}
export const environment= {
  project: 'settings-velux',
  authUrl: "https://auth.inte.velux-active.com",
  apiUrl: "https://app.inte.netatmo.com/api/",
  langsUrl: "/langs/",
  syncApiUrl: "https://app.inte.netatmo.com/syncapi/v1/",
  cookiePrefix: "inteveluxactivecom",
  env: EnumEnvs.INTEGRATION,
  mapboxAccessToken: 'pk.eyJ1IjoibmV0YXRtbyIsImEiOiJjanh5Z2U3NmIwMDQ2M2xzOTFsaXA0M2k2In0.uxP5nswLVJvjcm-AsGEPfQ',
  production: false
};